import type { Testimonial } from '../../state/testimonials';
import type { TemplateResult } from 'lit-element';
import type { Page } from '../../state/router';
import type { HTMLIncludeElement } from 'html-include-element';

import { customElement, html, property, queryAll } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html';

import { StateElement } from '@pwrs/state';

import 'html-include-element';

import '@material/mwc-button';

import '../define-element/define-element';

import { randomElem } from '../../lib/random';
import shared from '../../shared.css';
import style from './tbt-app.css';

type PageElement = HTMLElement & {
  active: boolean;
  dataset: {
    page: Page;
    canActivate: 'true'|'false';
    mustActivate: 'true'|'false';
  };
}

declare global {
  interface HTMLElementTagNameElementMap {
    'html-include': HTMLIncludeElement;
  }
}

const setLoadedAttribute = (el: HTMLElement): void => el.setAttribute('loaded', 'true');
const isHTMLInclude = ({ tagName }): HTMLIncludeElement => tagName === 'HTML-INCLUDE';
function onPartialLoad({ target }): void { setLoadedAttribute(target); }

@customElement('tbt-app') export class TbtApp extends StateElement {
  static readonly is = 'tbt-app';

  static readonly styles = [
    shared,
    style,
  ];

  @queryAll('#router-outlet > *') pages: NodeListOf<PageElement>;

  @property({ type: Object }) testimonial: Testimonial;

  render(): TemplateResult {
    return html`
      <section id="router-outlet">
        <tbt-subscribe data-page="subscribe"></tbt-subscribe>
        <tbt-account   data-page="account"></tbt-account>
        <html-include  data-page="chaburas"         src="/partials/chaburas.html"></html-include>
        <html-include  data-page="privacy"          src="/partials/privacy.html"></html-include>
        <html-include  data-page="terms-of-service" src="/partials/terms-of-service.html"></html-include>
        <html-include  data-page="home"             src="/partials/home.html">
          <tbt-card slot="testimonials">
            <h3 slot="heading">Testimonials</h3>
            <tbt-testimonial>
              <p>${unsafeHTML(this.testimonial && this.testimonial.content || '')}</p>
              <cite slot="author">${this.testimonial && this.testimonial.author || ''}</cite>
            </tbt-testimonial>
            <menu slot="actions">
              <mwc-button label="More" @click="${this.onClickNextTestimonial}" ?hidden="${!this.testimonial}"></mwc-button>
            </menu>
          </tbt-card>
        </html-include>
      </section>
    `;
  }

  protected firstUpdated(): void {
    const { testimonials = [] } = this.state;
    this.testimonial = randomElem(testimonials);
    // eslint-disable-next-line no-loops/no-loops
    for (const page of [...this.pages].filter(isHTMLInclude))
      page.addEventListener('load', onPartialLoad, { once: true });
  }

  protected updated(): void {
    // const activePage = this.state.router?.page
    const activePage = this.state.router && this.state.router.page;
    this.pages.forEach(function(child ) {
      const { dataset: { page, canActivate, mustActivate } } = child as PageElement;
      if (mustActivate === 'true' || (canActivate !== 'false' && activePage === page))
        child.setAttribute('active', 'true');
      else
        child.removeAttribute('active');
    });
  }

  private onClickNextTestimonial(): void {
    const { testimonials } = this.state;
    const currentidx = testimonials.indexOf(this.testimonial);
    const nextIdx = currentidx === testimonials.length - 1 ? 0 : currentidx + 1;
    this.testimonial = testimonials[nextIdx];
  }
}
